import "smartbanner.js";

// Replace the existing preconfigured CSS with our custom styles
// import "smartbanner.js/dist/smartbanner.min.css";

import { sendDataLayerEvent } from "./dataLayer";

function smartBannerJsView() {
  sendDataLayerEvent("smartbannerjs_view");
}

function smartBannerJsClickout() {
  sendDataLayerEvent("smartbannerjs_clickout");
  window.smartbanner?.exit();
}

function smartBannerJsExit() {
  sendDataLayerEvent("smartbannerjs_exit");
}

document.addEventListener("smartbanner.view", smartBannerJsView);
document.addEventListener("smartbanner.clickout", smartBannerJsClickout);
document.addEventListener("smartbanner.exit", smartBannerJsExit);

// Publishing needs to be triggered on `window.load`
window.addEventListener("load", () => {
  window.smartbanner?.publish();
});
